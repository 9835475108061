import { GraphicData } from '@/classes/stats/GraphicData'
import { Colors } from '@/classes/colors/Colors'

export class ChatBotsGraphicData {
  chatbotTasksData = null
  thatContext = null
  interactionItems = []

  constructor (chatbotTasksData, thatContext) {
    this.chatbotTasksData = chatbotTasksData
    this.thatContext = thatContext
  }

  getTasksGraphicData () {
    console.log(this.chatbotTasksData)
    const graphicData = new GraphicData(
      this.thatContext.$t('Ratio de resueltas'),
      [
        {
          label: this.thatContext.$t('Conocimiento caido').toString(),
          value: '<b>' + this.chatbotTasksData.records[0].totalComplete + '</b> / ',
          total: this.chatbotTasksData.records[0].total,
        },
        {
          label: this.thatContext.$t('Falta de información').toString(),
          value: '<b>' + this.chatbotTasksData.records[1].totalComplete + '</b> / ',
          total: this.chatbotTasksData.records[1].total,
        },
        {
          label: this.thatContext.$t('Respuesta incorrecta').toString(),
          value: '<b>' + this.chatbotTasksData.records[2].totalComplete + '</b> / ',
          total: this.chatbotTasksData.records[2].total,
        },
        {
          label: this.thatContext.$t('Validar respuesta por un agente').toString(),
          value: '<b>' + this.chatbotTasksData.records[3].totalComplete + '</b> / ',
          total: this.chatbotTasksData.records[3].total,
        },
        {
          label: this.thatContext.$t('Total resultas').toString(),
          value: '<b>' + this.chatbotTasksData.totals.totalComplete + '</b> / ',
          total: this.chatbotTasksData.totals.total,
        },
      ],
      [
        this.getPercentage(this.chatbotTasksData.totals.totalComplete, this.chatbotTasksData.totals.total),
        this.getPercentage(this.chatbotTasksData.records[0].totalComplete, this.chatbotTasksData.records[0].total),
        this.getPercentage(this.chatbotTasksData.records[1].totalComplete, this.chatbotTasksData.records[1].total),
        this.getPercentage(this.chatbotTasksData.records[2].totalComplete, this.chatbotTasksData.records[2].total),
        this.getPercentage(this.chatbotTasksData.records[3].totalComplete, this.chatbotTasksData.records[3].total),
      ],
      [
        this.thatContext.$t('Total resultas').toString(),
        this.thatContext.$t('Conocimiento caido').toString(),
        this.thatContext.$t('Falta de información').toString(),
        this.thatContext.$t('Respuesta incorrecta').toString(),
        this.thatContext.$t('Validar respuesta por un agente').toString(),
      ],
      this.getPercentage(this.chatbotTasksData.totals.totalComplete, this.chatbotTasksData.totals.total),
      this.thatContext.$t('Total resuletas').toString(),
      this.chatbotTasksData.totals.totalComplete,
      0,
      true,
      350,
      [
        Colors.statistics.SENT_TOTAL,
        Colors.statistics.DELIVERED_TOTAL,
        Colors.statistics.PENDING,
        Colors.statistics.UNDELIVERED_TOTAL,
        Colors.statistics.FAILED_TOTAL,
      ],
    )
    graphicData.legendProps.fontSize = '10px'

    return graphicData
  }

  getTasksPendingGraphicData () {
    console.log(this.chatbotTasksData)
    const graphicData = new GraphicData(
      this.thatContext.$t('Ratio de pendientes'),
      [
        {
          label: this.thatContext.$t('Conocimiento caido').toString(),
          value: '<b>' + this.chatbotTasksData.records[0].totalPending + '</b> / ',
          total: this.chatbotTasksData.records[0].total,
        },
        {
          label: this.thatContext.$t('Falta de información').toString(),
          value: '<b>' + this.chatbotTasksData.records[1].totalPending + '</b> / ',
          total: this.chatbotTasksData.records[1].total,
        },
        {
          label: this.thatContext.$t('Respuesta incorrecta').toString(),
          value: '<b>' + this.chatbotTasksData.records[2].totalPending + '</b> / ',
          total: this.chatbotTasksData.records[2].total,
        },
        {
          label: this.thatContext.$t('Validar respuesta por un agente').toString(),
          value: '<b>' + this.chatbotTasksData.records[3].totalPending + '</b> / ',
          total: this.chatbotTasksData.records[3].total,
        },
        {
          label: this.thatContext.$t('Total pendientes').toString(),
          value: '<b>' + this.chatbotTasksData.totals.totalPending + '</b> / ',
          total: this.chatbotTasksData.totals.total,
        },
      ],
      [
        this.getPercentage(this.chatbotTasksData.totals.totalPending, this.chatbotTasksData.totals.total),
        this.getPercentage(this.chatbotTasksData.records[0].totalPending, this.chatbotTasksData.records[0].total),
        this.getPercentage(this.chatbotTasksData.records[1].totalPending, this.chatbotTasksData.records[1].total),
        this.getPercentage(this.chatbotTasksData.records[2].totalPending, this.chatbotTasksData.records[2].total),
        this.getPercentage(this.chatbotTasksData.records[3].totalPending, this.chatbotTasksData.records[3].total),
      ],
      [
        this.thatContext.$t('Total pendientes').toString(),
        this.thatContext.$t('Conocimiento caido').toString(),
        this.thatContext.$t('Falta de información').toString(),
        this.thatContext.$t('Respuesta incorrecta').toString(),
        this.thatContext.$t('Validar respuesta por un agente').toString(),
      ],
      this.getPercentage(this.chatbotTasksData.totals.totalPending, this.chatbotTasksData.totals.total),
      this.thatContext.$t('Total pendientes').toString(),
      this.chatbotTasksData.totals.totalPending,
      0,
      true,
      350,
      [
        Colors.statistics.SENT_TOTAL,
        Colors.statistics.DELIVERED_TOTAL,
        Colors.statistics.PENDING,
        Colors.statistics.UNDELIVERED_TOTAL,
        Colors.statistics.FAILED_TOTAL,
      ],
    )
    graphicData.legendProps.fontSize = '10px'

    return graphicData
  }

  getPercentage (a, total) {
    return parseInt(a ?? 0) === 0 ? 0 : parseFloat((a / total) * 100).toFixed(0)
  }
}
